import { parseFilename, parseURL, stringifyParsedURL } from 'ufo'
import type { Metatags } from '~/types'

type Url = string

const isUrl = (val?: string) => {
  if (!val) return false
  return /^https?:\/\//.test(val)
}

const isImageUrl = (url: string) => {
  const filename = parseFilename(url, { strict: true })
  if (!filename) return false
  return /\.(jpe?g|gif|png|webp|svg)$/i.test(filename)
}

const useUnityOrgPath = (_path?: string) => {
  return stringifyParsedURL({
    ...parseURL(_path),
    host: 'www.unity.org',
    protocol: 'https:',
  })
}

export const useContentHeadMetatags = (
  metatags: MaybeRef<Metatags | undefined>,
) => {
  if (!unref(metatags)) return

  const img = useImage()
  const ogImage = (url: string) => {
    return img(unref(url), {
      width: 600,
      type: 'webp',
      quality: 80,
      fit: 'contain',
    })
  }

  const convertHref = (href?: Url) => {
    if (!href) return

    // If the link is an image, we need to replace the href an optimized version
    if (isImageUrl(href)) {
      return ogImage(href)
    }

    // Replace the href with the unity.org path if it's an internal link
    if (isUrl(href)) {
      return useUnityOrgPath(href)
    }

    return href
  }

  const link = computed(() => {
    const _metatags = unref(metatags)
    if (!_metatags?.link) return []

    return _metatags.link.map((link) => {
      if (!link.href) return link

      const href = convertHref(link.href)

      return {
        ...link,
        href,
      }
    })
  })

  const meta = computed(() => {
    const _metatags = unref(metatags)
    if (!_metatags?.meta) return []

    return _metatags.meta.map((meta) => {
      if (!meta.content) return meta

      const content = convertHref(meta.content)

      return {
        ...meta,
        content,
      }
    })
  })

  useHead({
    link,
    meta,
  })
}
