import { parseURL, withoutHost as _withoutHost } from 'ufo'

const isSameHost = (a: string, b: string): boolean => {
  const { host: hostA } = parseURL(a)
  const { host: hostB } = parseURL(b)
  return hostA === hostB
}

export const withoutHost = (url: string, baseUrl?: string): string => {
  if (baseUrl && !isSameHost(url, baseUrl)) {
    return url
  }
  return _withoutHost(url)
}
